import React, {useState, useEffect} from 'react'


export default function Snack() {
  return (
    <div style={{paddingTop: '68px'}} className="snacks-container">
      <div>
        <div>Financial news for the aspiring Wallstreet bets gambler</div>
        <div>The 3-minute newsletter with fresh insights on how to become rich.</div>
      </div>
      
    </div>
  )
}
